import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Link, Routes } from 'react-router-dom';

function App() {
  const [randomImage, setRandomImage] = useState(null);

  useEffect(() => {
    fetchRandomImage();
  }, []);

  const fetchRandomImage = async () => {
    try {
      const response = await fetch('/random-image');
      const data = await response.json();
      setRandomImage(data);
    } catch (error) {
      console.error('Error fetching random image:', error);
    }
  };

  return (
    <Router>
      <div className="App" style={{textAlign: 'center'}}>
        <h1>
          <Link to="/" className="title-link">tretton37 is a meme</Link>
        </h1>
        <Routes>
          <Route path="/" element={
            <>
              {randomImage && <img src={`/uploads/${randomImage.filename}`} alt="Random meme" style={{maxWidth: '100%', height: 'auto'}} />}
              <nav>
                <ul style={{listStyle: 'none', padding: 0}}>
                  <li style={{display: 'inline', margin: '0 10px'}}><Link to="/upload">Upload</Link></li>
                  <li style={{display: 'inline', margin: '0 10px'}}><Link to="/archive">Archive</Link></li>
                </ul>
              </nav>
            </>
          } />
          <Route path="/upload" element={<Upload />} />
          <Route path="/archive" element={<Archive />} />
        </Routes>
      </div>
    </Router>
  );
}

function Upload() {
  const [file, setFile] = useState(null);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append('image', file);
    try {
      const response = await fetch('/upload', { method: 'POST', body: formData });
      if (response.ok) {
        alert('Upload successful!');
      } else {
        alert('Upload failed.');
      }
    } catch (error) {
      console.error('Error uploading file:', error);
      alert('Upload failed.');
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <input type="file" onChange={e => setFile(e.target.files[0])} />
      <button type="submit">Upload</button>
    </form>
  );
}

function Archive() {
  const [images, setImages] = useState([]);

  useEffect(() => {
    fetchArchive();
  }, []);

  const fetchArchive = async () => {
    try {
      const response = await fetch('/archive');
      const data = await response.json();
      setImages(data);
    } catch (error) {
      console.error('Error fetching archive:', error);
    }
  };

  return (
    <div>
      {images.map(image => (
        <img key={image.id} src={`/uploads/${image.filename}`} alt="Archived meme" style={{maxWidth: '100%', height: 'auto', margin: '10px'}} />
      ))}
    </div>
  );
}

export default App;